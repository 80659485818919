<template>
    <div class="error">
        <div class="error_img center level_align">
            <img src="../assets/image/sousuo-wu.png" alt />
            <span>很抱歉，您还没有符合条件的{{name}}！</span>
        </div>
        <div class="error_footer">
            <div class="error_footer_head">建议您：</div>
            <ul class="error_footer_list">
                <li>1、进入首页，可以查看到更多可选课程；</li>
                <li>2、更换其他分类</li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        name: {
            type: String,
            default: "课程",
        },
    },
};
</script>

<style scoped lang="scss">
.error {
    width: 600px;
    margin: 0 auto;
    .error_img {
        img {
            width: 96px;
            height: 96px;
            margin-right: 20px;
        }
        span {
            font-size: 24px;
            color: #333333;
            font-weight: bold;
            line-height: 21px;
        }
    }
    .error_footer {
        padding-left: 116px;
        .error_footer_head {
            padding: 10px 0;
            color: #333333;
            font-size: 16px;
            margin-bottom: 10px;
            border-bottom: 1px solid #cccccc;
        }
        .error_footer_list {
            li {
                color: #666666;
                font-size: 14px;
                margin-bottom: 10px;
            }
        }
    }
}
</style>
