import {
	mapGetters,
	// mapActions
} from 'vuex'
var _this;
export default {
	computed: {
		...mapGetters(['userInfo'])
	},
	message(msg, type = 'warning') {
		_this.$message({
			message: msg,
			type: type
		})
	},
	created() {
		_this = this
	},
	methods: {
		// 判断是否登录
		judgeIsLogin(Bomb = true) {
			let a = true
			if (!this.$store.getters.getIsLogin) {
				if (Bomb) {
					this.$message({
						message: '请登录',
						type: 'warning'
					})
				}

				return a = false
			}
			return a
		},
		// 路由跳转
		pageJumps(name, param, isLogin, isTan = true) {
			if (isLogin) {  // 是否需要判断登录
				if (this.$store.getters.getIsLogin) {
					this.$router.push({
						name: name,
						query: param,
					});
				} else {
					this.$message({
						message: '请登录',
						type: 'warning'
					})
					if (isTan) {
						this.$store.commit('changeNoodeLogin', true)
						localStorage.removeItem('token')
						localStorage.removeItem('userInfo')
					}
				}
			} else {
				this.$router.push({
					name: name,
					query: param,
				});
			}
		},
		// 使用组件内的函数
		globalUseModuleFn(refs, fnName, val) {
			this.$refs[refs][fnName](val)
		},
		golobalBack() {
			this.$router.go(-1)
		},

		//去顶部
		backtop() {
			var timer = setInterval(function () {
				let osTop =
					document.documentElement.scrollTop || document.body.scrollTop;
				let ispeed = Math.floor(-osTop / 5);
				document.documentElement.scrollTop = document.body.scrollTop =
					osTop + ispeed;
				this.isTop = true;
				if (osTop === 0) {
					clearInterval(timer);
				}
			}, 10);
		},
		// 生成随机字符
		randomString(str_length) {
			const str_temp = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz0123456789";
			const str_temp_len = str_temp.length;
			const str_len = str_length || 16;
			let str = "";
			for (let i = 0; i < str_temp_len; i++) {
				str += str_temp.charAt(Math.floor(Math.random() * str_len));
			}
			return str
		},
		// 计算列表倒计时
		computeTime(finishTime) {
			//在后期出现了ios不显示的bug,经过测试发现需要把2020-02-03的日期格式中的-转换为/
			//新方法:finishTime = finishTime.replace(/-/g,"/").ios运行正常
			finishTime = finishTime.replace(/-/g, "/")
			if (typeof finishTime === 'string' && new Date(finishTime).toString() !== 'Invalid Date') {
				const now_time = new Date().getTime()
				const finish_time = new Date(finishTime).getTime()
				const sy_time = finish_time - now_time
				if (sy_time > 0) {
					// 时
					const hour = Math.floor(sy_time / (1000 * 60 * 60)).toString().padStart(2, '0')
					const sy_time1 = Math.floor(sy_time % (1000 * 60 * 60))
					// 分
					const min = Math.floor(sy_time1 / (1000 * 60)).toString().padStart(2, '0')
					const sy_time2 = Math.floor(sy_time1 % (1000 * 60))
					// 秒
					const sec = Math.floor(sy_time2 / (1000)).toString().padStart(2, '0')
					// 剩余时间
					const time_str = `${hour} : ${min} : ${sec}`
					return time_str
				} else {
					return '活动已结束'
				}
			} else {
				return '无效的结束时间'
			}
		},
		// 富文本图片适配
		parseRichImgStyle(content) {
			if (typeof content === "string" && content !== "") {
				let newContent = content.replace(/<img[^>]*>/gi, (match, capture) => {
					capture
					match = match.replace(/style="[^"]+"/gi, "").replace(/style='[^']+'/gi, "");
					match = match.replace(/width="[^"]+"/gi, "").replace(/width='[^']+'/gi, "");
					match = match.replace(/height="[^"]+"/gi, "").replace(/height='[^']+'/gi, "");
					return match;
				});
				newContent = newContent.replace(/style="[^"]+"/gi, (match, capture) => {
					capture
					match = match.replace(/width:[^;]+;/gi, "max-width:100%;").replace(/width:[^;]+;/gi, "max-width:100%;");
					return match;
				});
				newContent = newContent.replace(/<br[^>]*\/>/gi, "");
				newContent = newContent.replace(/<img/gi, "<img style=\"max-width:100%;height:auto;display:block;margin-top:0;margin-bottom:0;\"");
				return newContent;
			} else {
				return "";
			}
		},
	}
}

