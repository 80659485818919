import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';

import Videojs from 'video.js'
import 'video.js/dist/video-js.css'
import 'element-ui/lib/theme-chalk/index.css';
import axios from './axios/index'
import api from './axios/api'
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'
import Error from './components/Error.vue'
import Warm from './components/Warm.vue'
import globalFn from "./utils/globalFn.js";
Vue.component('Header', Header)
Vue.component('Footer', Footer)
Vue.component('Error', Error)
Vue.component('Warm', Warm)
Vue.prototype.$axios = axios
Vue.prototype.$api = api
Vue.prototype.$regexPhone = /^(0|86|17951)?(13[0-9]|15[012356789]|166|17[3678]|18[0-9]|14[57])[0-9]{8}$/
Vue.prototype.$video = Videojs
Vue.use(ElementUI);
Vue.config.productionTip = false
Vue.mixin(globalFn);

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
