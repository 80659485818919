<template>
    <div class="login-dia">
        <el-dialog title="提示" :visible.sync="centerDialogVisible" width="700px" center>
            <div class="column_align" v-if="!isInput">
                <img
                    class="yh-imgObject weidenglu-img yh-mt60"
                    src="../assets/image/weidengl.png"
                    alt
                    width="189"
                    height="171"
                />
                <p class="yh-fc-yellow">您当前未登录账户，无法查看</p>
                <button
                    class="login-btn yh-fz24 yh-fcF yh-mt80 yh-cp yh-br4"
                    @click="isInput = true"
                >立即登录</button>
            </div>
            <div class="column_align" v-if="isInput">
                <input
                    class="yh-w520 yh-h64 yh-fz26 yh-ptb0lr20 yh-bbox yh-mt60 yh-border1"
                    type="text"
                    v-model="account"
                    maxlength="18"
                    placeholder="手机号或身份证号"
                    placeholder-class="yh-fc999"
                />
                <input
                    class="yh-w520 yh-h64 yh-fz26 yh-ptb0lr20 yh-bbox yh-mt30 yh-border1"
                    type="password"
                    v-model="password"
                    @keyup.enter="loginFn"
                    placeholder="请输入密码"
                />
                <div class="level_align flex_between yh-w520 yh-lh56 yh-fz16 yh-mt20 yh-ptb0lr10">
                    <span class="yh-cp yh-hover-fc" @click="hide('register')">注册</span>
                    <span class="yh-cp yh-hover-fc" @click="hide('forgetPassword')">忘记密码?</span>
                </div>
                <button @click="loginFn" class="login-btn yh-fz24 yh-fcF yh-mt60 yh-cp yh-br4">立即登录</button>
            </div>
        </el-dialog>
        <register ref="register"></register>
        <forgetPassword ref="forgetPassword"></forgetPassword>
    </div>
</template>

<script>
import register from "@/components/register";
import forgetPassword from "@/components/forgetPassword";
export default {
    name: "login",
    components: {
        register,
        forgetPassword,
    },
    data() {
        return {
            centerDialogVisible: false, // 弹框
            isInput: false, // 是否显示input
            account: "", //手机号
            password: "", //密码
        };
    },
    created() {},
    methods: {
        //登录
        loginFn() {
            if (!this.account)
                return this.$message({
                    message: "请输入手机号或身份证号",
                    type: "warning",
                });
            if (!this.password)
                return this.$message({
                    message: "请输入密码",
                    type: "warning",
                });
            this.$axios
                .post(this.$api.accountLogin, {
                    account: this.account,
                    password: this.password,
                })
                .then((res) => {
                    if (res.code == 1) {
                        this.$message({
                            message: res.msg,
                            type: "success",
                        });
                        // localStorage.setItem("token", res.data.token);
                        sessionStorage.setItem("token", res.data.token);
                        this.$store.commit("upIsLogin", true);
                        this.centerDialogVisible = false;
                        this.$axios.get(this.$api.myinfo).then((ress) => {
                            if (ress.code === 1) {
                                this.$store.commit("upUserInfo", ress.data);
                            }
                        });
                        location.reload();
                    }
                });
        },
        show() {
            this.centerDialogVisible = true;
            this.$store.commit("changeNoodeLogin", false);
            this.account =
                (localStorage.getItem("account") &&
                    localStorage.getItem("account")) ||
                "";
            this.password =
                (localStorage.getItem("password") &&
                    localStorage.getItem("password")) ||
                "";
        },
        // 关闭
        hide(name) {
            this.centerDialogVisible = false;
            this.globalUseModuleFn(name, "show");
        },
    },
};
</script>

<style  lang="scss">
.login-dia {
    .el-dialog__header {
        background: #f7faff;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        padding: 10px 20px;
        font-size: 26px;
    }

    .el-dialog__title {
        color: #1263ff;
    }
    .el-dialog__headerbtn .el-dialog__close {
        color: #d9d9d9;
    }
    .el-icon-close:before {
        content: "\e79d";
    }
}

.weidenglu-img {
    width: 189px;
    height: 171px;
}

.login-btn {
    width: 520px;
    height: 68px;
    background: #1263ff;
}
</style>
