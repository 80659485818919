import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		isLogin: (sessionStorage.getItem('token') && sessionStorage.getItem('token')) !== null, // 是否登录  有token 已经登录 false 未登录
		needLogin: false, // 需要登录 弹框用  false 不需要 true 需要
		needRenzheng: false, // 需要认证 弹框用 false 不需要 true 需要
		userInfo: JSON.parse(sessionStorage.getItem('userInfo')) || {},
		searchValue: '', // 搜索的值
	},
	mutations: {
		upIsLogin(state, val) {
			state.isLogin = val
		},
		changeNoodeLogin(state, val) {
			state.needLogin = val
		},
		// 认证
		changeNoodeRenzheng(state, val) {
			state.needRenzheng = val
		},
		// 设置个人信息
		upUserInfo(state, data) {
			state.userInfo = data
			sessionStorage.setItem('userInfo', JSON.stringify(data))
		},
		// 更新搜索的值
		upSearchValue(state, data) {
			state.searchValue = data
		},
	},
	getters: {
		//获取是否已经登录
		getIsLogin(state) {
			return state.isLogin
		},
		//获取是否需要登录
		needLogin(state) {
			return state.needLogin;
		},
		//  获取认证 是否需要认证
		needRenzheng(state) {
			return state.needRenzheng
		},
		userInfo(state) {
			return state.userInfo
		},
		// 获取搜索的值
		getSeachValue(state) {
			return state.searchValue
		}
	},
	actions: {

	},
	modules: {
	}
})
