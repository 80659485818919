<template>
    <div class="login-dia">
        <el-dialog
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            title="报名"
            :visible.sync="centerDialogVisible"
            width="700px"
            center
        >
            <div class="yh-ptb0lr30 yh-bbox">
                <el-form
                    :model="ruleForm"
                    :rules="rules"
                    ref="ruleForm"
                    label-width="20px"
                    class="demo-ruleForm"
                >
                    <el-form-item label=" " prop="birth_place">
                        <el-input v-model="ruleForm.birth_place" placeholder="户口所在地"></el-input>
                    </el-form-item>
                    <el-form-item label=" " prop="addressArr">
                        <el-cascader
                            class="yh-w100"
                            v-model="ruleForm.addressArr"
                            :options="citySelectionList"
                            placeholder="请选择居住所在地"
                            @change="changeArea"
                        ></el-cascader>
                    </el-form-item>
                    <el-form-item label=" " prop="work_placeArr">
                        <el-cascader
                            class="yh-w100"
                            v-model="ruleForm.work_placeArr"
                            :options="citySelectionList"
                            placeholder="请选择就业所在地"
                            @change="changeArea"
                        ></el-cascader>
                    </el-form-item>
                    <el-form-item label=" " prop="address_type">
                        <el-select
                            class="yh-w100"
                            v-model="ruleForm.address_type"
                            placeholder="请选择居住性质"
                        >
                            <el-option
                                :label="item"
                                v-for="(item,index) in address_type"
                                :key="index"
                                :value="item"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label=" " prop="learning_type">
                        <el-select
                            class="yh-w100"
                            v-model="ruleForm.learning_type"
                            placeholder="请选择学习形式"
                        >
                            <el-option
                                :label="item"
                                v-for="(item,index) in studylist"
                                :key="index"
                                :value="item"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label=" " prop="user_type">
                        <el-select
                            class="yh-w100"
                            v-model="ruleForm.user_type"
                            placeholder="请选择人员类别"
                        >
                            <el-option
                                :label="item"
                                v-for="(item,index) in user_type"
                                :key="index"
                                :value="item"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label=" " prop="company">
                        <el-input v-model="ruleForm.company" placeholder="单位名称(没有写无)"></el-input>
                    </el-form-item>
                    <el-form-item label=" " prop="company">
                        证明材料
                        <span class="yh-fc999">（半年以上劳务合同）</span>
                        <div class="yh-ptb0lr20 yh-bbox yh-br8 level_align">
                            <el-upload
                                class="avatar-uploader yh-mr20"
                                action="https://hnqkx.zzwangxiao.com/api/upload/upload_one_image"
                                :show-file-list="false"
                                :headers="{
									token: token,
								}"
                                accept="image"
                                name="image"
                                :on-success="handleAvatarSuccessFirst"
                            >
                                <img
                                    v-if="ruleForm.certificate_first"
                                    :src="certificate_first"
                                    class="avatar"
                                />
                                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                            </el-upload>
                            <el-upload
                                class="avatar-uploader yh-mr30"
                                action="https://hnqkx.zzwangxiao.com/api/upload/upload_one_image"
                                :show-file-list="false"
                                :headers="{
									token: token,
								}"
                                accept="image"
                                name="image"
                                :on-success="handleAvatarSuccessSecond"
                            >
                                <img
                                    v-if="ruleForm.certificate_second"
                                    :src="certificate_second"
                                    class="avatar"
                                />
                                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                            </el-upload>
                            <div class="center_combo yh-h100 yh-fc-yellow">
                                注:请上传合同正文第一页
                                和包含公章的最后一页。
                            </div>
                        </div>
                    </el-form-item>
                    <el-form-item label=" " prop="company">
                        证书头像
                        <span class="yh-fc999">（一寸证件照）</span>
                        <div class="yh-ptb0lr20 yh-bbox yh-br8 level_align">
                            <el-upload
                                class="avatar-uploader yh-mr20"
                                action="https://hnqkx.zzwangxiao.com/api/upload/upload_one_image"
                                :show-file-list="false"
                                :headers="{
									token: token,
								}"
                                accept="image"
                                name="image"
                                :on-success="handleAvatarSuccessAavatar"
                            >
                                <img v-if="ruleForm.avatar" :src="avatar" class="avatar" />
                                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                            </el-upload>
                        </div>
                    </el-form-item>
                </el-form>
                <div class="center_combo">
                    <button
                        @click="submit"
                        class="login-btn yh-fz24 yh-fcF yh-cp yh-br4 yh-m0a"
                    >确认提交</button>
                </div>
                <div class="level_align yh-mt30" @click="isRead = !isRead">
                    <img class="yh-img26 yh-mr20" v-if="isRead" src="../assets/image/yudu.png" alt />
                    <img class="yh-img26 yh-mr20" v-else src="../assets/image/yudu2.png" alt />
                    我已阅读并同意提交承诺书
                    <span
                        class="yh-fc-blue"
                        @click.stop="showText"
                    >《郑州市公共实训网络服务管理平台线上学习承诺书》</span>
                </div>
            </div>
        </el-dialog>
        <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt />
        </el-dialog>
        <notice ref="notice" :noticeInfo="noticeInfo" @NoticeOk="isRead = true"></notice>
        <warm ref="warm"></warm>
    </div>
</template>

<script>
import cascaderData from "@/assets/city-data/city-data.js";
import notice from "@/components/notice";
import warm from "@/components/Warm";
import $DB from "@/utils/debounce";
export default {
    name: "apply",
    components: {
        notice,
        warm,
    },
    data() {
        return {
            centerDialogVisible: false, // 弹框
            dialogVisible: false, // 查看大图的
            isInput: false, // 是否显示input
            isRead: false, // 是否阅读
            current: {}, // 当前要报名的
            citySelectionList: cascaderData, // 城市列表

            dialogImageUrl: "", // 查看大图
            certificate_first: "", // 合同页
            certificate_second: "", // 公章页
            avatar: "", // 头像
            ruleForm: {
                course_id: "", // 课程id
                birth_place: "", // 	户口所在地
                address: "", // 	居住所在地
                work_place: "", // 就业所在地
                addressArr: [], // 	居住所在地
                work_placeArr: [], // 就业所在地
                address_type: "", // 居住性质
                user_type: "", // 人员类别
                learning_type: "", //学习形式
                company: "", // 单位名称
                certificate_first: "", // 合同页id
                certificate_second: "", // 公章页id
                avatar: "", // 头像id
            },
            rules: {
                birth_place: [
                    {
                        required: true,
                        message: "请输入户口所在地",
                        trigger: "blur",
                    },
                ],
                addressArr: [
                    {
                        required: true,
                        message: "请选择居住所在地",
                        trigger: "change",
                    },
                ],
                work_placeArr: [
                    {
                        required: true,
                        message: "请选择就业所在地",
                        trigger: "change",
                    },
                ],
                address_type: [
                    {
                        required: true,
                        message: "请选择居住性质",
                        trigger: "change",
                    },
                ],
                learning_type: [
                    {
                        required: true,
                        message: "请选择学习性质",
                        trigger: "change",
                    },
                ],
                user_type: [
                    {
                        required: true,
                        message: "请选择人员类别",
                        trigger: "change",
                    },
                ],
                company: [
                    {
                        required: true,
                        message: "请输入单位名称",
                        trigger: "blur",
                    },
                ],
            },
            address_type: [], // 居住性质
            user_type: [], // 人员类别
            studylist: ["线上", "线下", "线上+线下"], // 学习方式
            token: "",
            noticeInfo: {
                textTitle: "郑州市公共实训网络服务管理平台线上学习承诺书",
                context:
                    "<p>sjskndfnkasd口味儿奶粉我女人放哪个无奈地防晒呢的咖啡你可能</p>",
            },
        };
    },
    created() {
      this.get_info()
        this.token = sessionStorage.getItem("token");
        this.getSignUpOption();
        this.$axios.get(this.$api.signupNotice).then((res) => {
            if (res.code == 1) {
                this.noticeInfo.context = res.data.value;
                this.noticeInfo.textTitle = res.data.title;
            }
        });
    },
    methods: {
      get_info () {
        this.$axios.post(this.$api.get_info).then(res=>{
          if (res.code == 1) {
            this.ruleForm.birth_place = res.data.birth_place
            this.ruleForm.addressArr =res.data.address_j.split('-')
            this.ruleForm.work_placeArr =res.data.address_j.split('-')
            this.ruleForm.address_type = res.data.address_type
            this.ruleForm.learning_type = res.data.learning_type
            this.ruleForm.user_type = res.data.user_type
            this.ruleForm.company = res.data.auth_jiuye
            this.ruleForm.work_placeArr = res.data.work_place.split('-')
          }
        })
      },
        // 提交
        submit: $DB(function () {
            this.ruleForm.address = this.ruleForm.addressArr.join("-");
            this.ruleForm.work_place = this.ruleForm.work_placeArr.join("-");
            this.ruleForm.course_id = this.current.course_id;
            let {
                address,
                work_place,
                address_type,
                user_type,
                learning_type,
                company,
                certificate_first,
                certificate_second,
                avatar,
            } = this.ruleForm;

            if (!address)
                return this.$message({
                    message: "请选择居住所在地",
                    type: "warning",
                });
            if (!work_place)
                return this.$message({
                    message: "请选择就业所在地",
                    type: "warning",
                });
            if (!address_type)
                return this.$message({
                    message: "请选择居住性质",
                    type: "warning",
                });
            if (!user_type)
                return this.$message({
                    message: "请选择人员类别",
                    type: "warning",
                });
            if (!learning_type)
                return this.$message({
                    message: "请选择学习形式",
                    type: "warning",
                });
            if (!company)
                return this.$message({
                    message: "请填写单位名称",
                    type: "warning",
                });
            if (!certificate_first)
                return this.$message({
                    message: "请上传证明材料第一页",
                    type: "warning",
                });
            if (!certificate_second)
                return this.$message({
                    message: "请上传证明材料最后一页",
                    type: "warning",
                });
            if (!avatar)
                return this.$message({
                    message: "请上传头像",
                    type: "warning",
                });
            if (!this.isRead)
                return this.$message({
                    message: "请阅读协议",
                    type: "warning",
                });
            this.$axios.post(this.$api.sign_up, this.ruleForm).then((res) => {
                if (res.code == 1) {
                    this.$message({
                        message: res.msg,
                        type: "success",
                    });
                    this.centerDialogVisible = false;
                    this.$refs.warm.show(this.current.course_id);
                }
            });
        }),
        // 合同成功
        handleAvatarSuccessFirst(event, file) {
            this.ruleForm.certificate_first = event.data.file_id;
            this.certificate_first = URL.createObjectURL(file.raw);
        },
        // 公章页成功
        handleAvatarSuccessSecond(event, file) {
            this.ruleForm.certificate_second = event.data.file_id;
            this.certificate_second = URL.createObjectURL(file.raw);
        },
        // 头像成功
        handleAvatarSuccessAavatar(event, file) {
            this.ruleForm.avatar = event.data.file_id;
            this.avatar = URL.createObjectURL(file.raw);
        },
        // 获取报名选项
        getSignUpOption() {
            this.$axios.post(this.$api.signUpOption).then((res) => {
                if (res.code === 1) {
                    this.address_type = res.data.address_type;
                    this.user_type = res.data.user_type;
                }
            });
        },
        show(item) {
            if (item) {
                this.current = item;
                console.log("课程详情", this.current);
                this.centerDialogVisible = true;
            }
        },
        // 显示文本说明
        showText() {
            this.$refs.notice.show();
        },
        //改变区域e
        changeArea(e) {
            console.log(e);
        },
    },
};
</script>

<style  lang="scss">
.login-dia {
    .el-dialog__header {
        background: #f7faff;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        padding: 10px 20px;
        font-size: 26px;
    }

    .el-dialog__title {
        color: #1263ff;
    }

    .el-dialog__headerbtn .el-dialog__close {
        color: #d9d9d9;
    }

    .el-icon-close:before {
        content: "\e79d";
    }
    .el-dialog__headerbtn {
        font-size: 22px;
    }
}

.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}
.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 130px;
    height: 130px;
    line-height: 130px !important;
    text-align: center;
}
.avatar {
    width: 130px;
    height: 130px;
    display: block;
}
.login-btn {
    width: 520px;
    height: 68px;
    background: #1263ff;
}
</style>
