<template>
    <div class="warm flex flex_center align_center flex_column">
        <el-dialog title="温馨提示" :visible.sync="centerDialogVisible" width="686px" center>
            <div class="warm_img flex_column flex flex_center align_center">
                <img src="../assets/image/shenhez.png" alt />
                <span>报名信息已提交看，审核中请耐心等待~</span>
            </div>
            <div class="warm_footer flex align_center flex_center">
                <el-button class="warm_btn flex align_center flex_center" @click="Confirm">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            centerDialogVisible: false, // 弹框
            id: "",
        };
    },
    methods: {
        show(id = "") {
            this.id = id;
            this.centerDialogVisible = true;
        },
        Confirm() {
            this.centerDialogVisible = false;
            if (this.id) {
                this.pageJumps("courseDetails", { id: this.id });
            }
        },
    },
};
</script>

<style lang="scss" >
.warm {
    .el-dialog__header {
        background: #f7faff;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        padding: 10px 20px;
        font-size: 26px;
    }

    .el-dialog__title {
        color: #1263ff;
    }
    .el-dialog__headerbtn .el-dialog__close {
        color: #d9d9d9;
    }
    .el-icon-close:before {
        content: "\e79d";
    }
    .warm_img {
        padding: 45px 0 40px 0;
        img {
            width: 189px;
            height: 171px;
        }
        span {
            width: 191px;
            color: #ff8a00;
            font-size: 16px;
            line-height: 44px;
            text-align: center;
        }
    }
    .warm_btn {
        width: 526px;
        height: 68px;
        color: #ffffff;
        font-size: 24px;
        background: #1263ff;
        border-radius: 6px;
    }
}
</style>
