<template>
    <div class="login-dia">
        <el-dialog title="实名认证" :visible.sync="centerDialogVisible" width="1200px" center>
            <div class>
                <div class="level_align flex_around">
                    <div class="column_align">
                        <h3 class="yh-lh64">请上传您的身份证人像面</h3>
                        <div class="zheng-img yh-pd20 yh-bcF5 yh-br8 yh-mr20 yh-pr">
                            <el-upload
                                v-if="!idcard_front"
                                action="https://hnqkx.zzwangxiao.com/api/upload/upload_one_image"
                                :headers="{
                  token: token,
                }"
                                accept="image"
                                name="image"
                                :show-file-list="false"
                                :on-success="handleAvatarSuccess"
                            >
                                <img
                                    class="yh-cp"
                                    src="../assets/image/p-shenfenzheng-zheng.png"
                                    alt
                                />
                                <img
                                    class="paishe-img yh-pxyc"
                                    src="../assets/image/paishe.png"
                                    alt
                                />
                            </el-upload>
                            <img v-if="idcard_front" class="yh-imgObject" :src="idcard_front1" alt />
                        </div>
                        <p class="yh-lh56 yh-fc999">点击上传</p>
                    </div>

                    <div class="column_align">
                        <h3 class="yh-lh64">请上传您的身份证国徽面</h3>
                        <div class="zheng-img yh-pd20 yh-bcF5 yh-br8 yh-mr20 yh-pr">
                            <el-upload
                                v-if="!idcard_back"
                                action="https://hnqkx.zzwangxiao.com/api/upload/upload_one_image"
                                :headers="{
                  token: token,
                }"
                                accept="image"
                                name="image"
                                :show-file-list="false"
                                :on-success="handleAvatarSuccessBack"
                            >
                                <img
                                    class="yh-cp"
                                    src="../assets/image/p-shenfenzheng-zheng.png"
                                    alt
                                />
                                <img
                                    class="paishe-img yh-pxyc"
                                    src="../assets/image/paishe.png"
                                    alt
                                />
                            </el-upload>
                            <img v-if="idcard_back" class="yh-imgObject" :src="idcard_back1" alt />
                        </div>
                        <p class="yh-lh56 yh-fc999">点击上传</p>
                    </div>
                </div>
                <div class="center_combo">
                    <button
                        @click="submit"
                        class="login-btn yh-fz24 yh-bc yh-w520 yh-h64 yh-fcF yh-mt80 yh-cp yh-br4"
                    >提交</button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import $DB from "@/utils/debounce";
export default {
    name: "authentication",
    data() {
        return {
            centerDialogVisible: false, // 弹框
            token: "",
            idcard_front: "", // 正面照id
            idcard_front1: "", // 正面照
            idcard_back: "", // 反面照id
            idcard_back1: "", // 反面照
        };
    },
    created() {
        this.token = sessionStorage.getItem("token");
    },
    methods: {
        show() {
            this.centerDialogVisible = true;
            this.$store.commit("changeNoodeRenzheng", false);
        },
        // 正面成功
        handleAvatarSuccess(event, file) {
            this.idcard_front = event.data.file_id;
            this.idcard_front1 = URL.createObjectURL(file.raw);
        },
        // 反面成功
        handleAvatarSuccessBack(event, file) {
            this.idcard_back = event.data.file_id;
            this.idcard_back1 = URL.createObjectURL(file.raw);
        },
        submit: $DB(function () {
            if (!this.idcard_front)
                return this.$message({
                    message: "请选择人像面身份证",
                    type: "warning",
                });
            if (!this.idcard_back)
                return this.$message({
                    message: "请选择国徽面身份证",
                    type: "warning",
                });
            this.$axios
                .post(this.$api.realNameAuth, {
                    idcard_front: this.idcard_front,
                    idcard_back: this.idcard_back,
                })
                .then((res) => {
                    if (res.code == 1) {
                        this.$message({
                            message: res.msg,
                            type: "warning",
                        });
                        this.$axios.get(this.$api.myinfo).then((ress) => {
                            if (ress.code === 1) {
                                this.$store.commit("upUserInfo", ress.data);
                            }
                        });
                    }
                });
        }),
    },
};
</script>

<style lang="scss">
.login-dia {
    .el-dialog__header {
        background: #f7faff;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        padding: 10px 20px;
        font-size: 26px;
    }

    .el-dialog__title {
        color: #1263ff;
    }
    .el-dialog__headerbtn .el-dialog__close {
        color: #d9d9d9;
    }
    .el-icon-close:before {
        content: "\e79d";
    }
}
.zheng-img {
    width: 368px;
    height: 232px;
}
.paishe-img {
    width: 154px;
    height: 124px;
}
</style>
