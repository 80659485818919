<script src="../../utils/globalFn.js"></script>
<template>
    <div class="home">
        <!-- S 轮播图 -->
        <div class="home_swiper">
            <el-carousel height="290px" loop>
                <el-carousel-item v-for="item in bannerList" :key="item.content_id">
                    <img :src="item.image" draggable="false" alt />
                </el-carousel-item>
            </el-carousel>
            <div class="home_swiper_logo">
                <div class="home_swiper_logo_wu" v-if="!isLogin">
                    <div class="home_swiper_logo_wu_head flex align_center flex_center">学员登录</div>
                    <ul class="home_swiper_logo_list">
                        <li>
                            <input
                                type="text"
                                maxlength="18"
                                v-model="account"
                                placeholder="手机号或身份证号"
                            />
                        </li>
                        <li>
                            <input
                                type="password"
                                v-model="password"
                                @keyup.enter="login"
                                placeholder="请输入密码"
                            />
                        </li>
                    </ul>
                    <div class="home_swiper_logo_wu_btns">
                        <el-button class="home_swiper_logo_wu_btn" @click="login">立即登录</el-button>
                    </div>
                    <div class="home_swiper_logo_wu_footer flex flex_between">
                        <div class="home_swiper_logo_wu_footer_l">
                            <div class="remember flex">
                                <div
                                    class="remember_img level_align"
                                    @click="rememberPwd = !rememberPwd"
                                >
                                    <img
                                        v-if="rememberPwd"
                                        src="../../assets/image/miama-k2.png"
                                        alt
                                    />
                                    <img v-else src="../../assets/image/miama-k1.png" alt />
                                    <span>记住密码</span>
                                </div>
                            </div>
                        </div>
                        <div
                            class="remember_register yh-fc666 yh-fz12 yh-cp"
                            @click="globalUseModuleFn('register','show')"
                        >注册</div>
                        <div
                            class="home_swiper_logo_wu_footer_r"
                            @click="globalUseModuleFn('forgetPassword','show')"
                        >忘记密码</div>
                    </div>
                </div>
                <div class="home_swiper_logo_have" v-if="isLogin">
                    <el-dropdown placement="bottom" @command="dropdownFn">
                        <div class="home_swiper_logo_have_head flex flex_center align_center">
                            <img :src="userInfo.avatar" alt class="head" />
                            <span>{{userInfo.nickname || ''}}</span>
                            <img src="../../assets/image/xiala.png" alt class="xiala" />
                        </div>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item command="学习">学习中心</el-dropdown-item>
                            <el-dropdown-item command="退出">退出登录</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>

                    <div class="home_swiper_logo_have_box">
                        <ul class="home_swiper_logo_have_list">
                            <li
                                class="yh-cp"
                                v-for="(item,index) in myCoursesList"
                                :key="index"
                                @click="pageJumps('courseDetails',{'id':item.course_id},true)"
                            >
                                <div class="home_swiper_logo_have_img yh-img20">
                                    <img :src="item.image" alt />
                                </div>
                                <div class="home_swiper_logo_have_txt">
                                    <div class="home_swiper_logo_have_txt_name">{{item.course_name}}</div>
                                    <div
                                        class="home_swiper_logo_have_txt_time"
                                    >结束时间{{item.end_time}}</div>
                                    <div class="home_swiper_logo_have_txt_jindu">
                                        <div class="dot" :style="{width: item.task + '%'}"></div>
                                    </div>
                                    <div class="home_swiper_logo_have_txt_stude flexs">
                                        <span>学习中:</span>
                                        <span>{{item.task}}%</span>
                                    </div>
                                </div>
                            </li>
                            <li
                                v-if="myCoursesList.length == 0"
                                class="yh-lh44 center_combo"
                            >暂无课程，快去报名吧~</li>
                        </ul>
                    </div>

                    <div
                        @click="goToCourse"
                        class="home_swiper_logo_have_btn flex align_center flex_center"
                    >查看课程</div>
                </div>
            </div>
        </div>
        <!-- E -->
        <!-- S 中间公告 新闻 -->
        <div class="home_advertising">
            <ul class="home_advertising_list flex flex_between align_center">
                <li v-for="(item,index) in consultList" :key="index">
                    <div class="home_advertising_list_head flex flex_between align_center">
                        <div class="home_advertising_list_name">{{item.name}}</div>
                        <div class="home_advertising_list_more" @click="pageJumps('news')">更多>></div>
                    </div>
                    <div class="home_advertising_list_bot">
                        <div
                            class="home_advertising_list_li"
                            v-for="(items,inde) in item.list"
                            :key="inde"
                            @click="goNoticeMessage(items.id,item.type)"
                        >{{items.title}}</div>
                    </div>
                </li>
                <!-- <li>
          <div class="home_advertising_list_head flex flex_between align_center">
            <div class="home_advertising_list_name">政策法规</div>
            <div class="home_advertising_list_more">更多>></div>
          </div>
          <div class="home_advertising_list_bot">
            <div class="home_advertising_list_li" v-for="(item,index) in 8" :key="index">人社部财政部:实施职业技能提升行动“互联网+职业技人社部财政部:实施职业技能提升行动“互</div>
          </div>
        </li>
        <li>
          <div class="home_advertising_list_head flex flex_between align_center">
            <div class="home_advertising_list_name">工作动态</div>
            <div class="home_advertising_list_more">更多>></div>
          </div>
          <div class="home_advertising_list_bot">
            <div class="home_advertising_list_li" v-for="(item,index) in 8" :key="index">人社部财政部:实施职业技能提升行动“互联网+职业技人社部财政部:实施职业技能提升行动“互</div>
          </div>
                </li>-->
            </ul>
        </div>
        <!-- E 中间公告 -->
        <!-- S 外部链接 -->
        <div class="home_link" @click="openWindow(adInfo.px_url)">
            <img :src="adInfo.px_image" alt />
        </div>
        <!-- E 外部链接 -->
        <!-- S 培训课程 -->
        <div class="home_course">
            <div class="home_course_head flex align_center flex_between">
                <ul class="home_course_head_list flex">
                    <li class="active">培训课程</li>
                </ul>
                <div class="home_course_head_more" @click="pageJumps('Course')">更多>></div>
            </div>
            <div class="home_course_main">
                <ul class="home_course_main_list">
                    <li
                        v-for="(item,index) in trainList"
                        :key="index"
                        @click="pageJumps('CourseMessage',{id: item.course_id},true,false)"
                    >
                        <div class="home_course_main_list_img">
                            <img :src="item.image" alt />
                            <span>学习人数 {{item.user_num || item.show_user_num || 0}}</span>
                        </div>
                        <div class="home_course_main_list_txt">
                            <div class="home_course_main_list_name">{{item.name}}</div>
                            <div
                                class="home_course_main_list_title flex flex_between align_center level_align yh-fc999 yh-fz16"
                            >
                                <div class="home_course_main_list_title_l flex level_align">
                                    <span>学时：</span>
                                    <span>{{item.hours || 0}}</span>
                                </div>
                                <span class="yh-fz14 fc999">|</span>
                                <span class="yh-fc999 yh-fz12">{{item.tag}}</span>
                                <div
                                    v-if="!item.is_paid || item.is_paid == 0"
                                    @click.stop="applySubmit(item)"
                                    class="home_course_main_list_title_btn flex align_center flex_center yh-ml20"
                                >立即报名</div>
                                <div
                                    v-if="item.is_paid && item.is_paid == 1"
                                    @click.stop="pageJumps('courseDetails',{'id':item.id},true)"
                                    class="home_course_main_list_title_btn flex align_center flex_center yh-ml20"
                                >继续学习</div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <!-- E 培训课程 -->
        <!-- S 外部链接2 -->
        <div class="home_link" @click="openWindow(adInfo.dz_url)">
            <img :src="adInfo.dz_image" alt />
        </div>
        <!-- E 外部链接2 -->
        <!-- S 定制课程 -->
        <div class="home_course">
            <div class="home_course_head flex align_center flex_between">
                <ul class="home_course_head_list flex">
                    <li class="active">定制课程</li>
                </ul>
                <div class="home_course_head_more" @click="pageJumps('Customization')">更多>></div>
            </div>
            <div class="home_course_main">
                <ul class="home_course_main_list">
                    <li
                        v-for="(item,index) in recommendDzcourseList"
                        :key="index"
                        @click="pageJumps('CustomizationMessage', {id:item.id})"
                    >
                        <div class="home_course_main_list_img">
                            <img :src="item.image" alt />
                            <span>开班时间:{{item.time}}</span>
                        </div>
                        <div class="home_course_main_list_txt">
                            <div class="home_course_main_list_name">{{item.name}}</div>
                            <div class="home_course_main_list_title flex align_center flex_between">
                                <div class="home_course_main_list_title_l flexs">
                                    <span>状态：</span>
                                    <span>{{item.status}}</span>
                                </div>
                                <div class="home_course_main_list_title_l flexs">
                                    <span>学习形式：</span>
                                    <span>{{item.learning_type}}</span>
                                </div>
                                <!-- <div class="home_course_main_list_title_btn center">立即报名</div> -->
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <!-- E 培训定制课程课程 -->
        <!-- S 外部链接3 -->
        <div class="home_link" @click="openWindow(adInfo.ds_url)">
            <img :src="adInfo.ds_image" alt />
        </div>
        <!-- E 外部链接3 -->
        <!-- S 非遗 工匠 -->
        <div class="home_course">
            <div class="home_course_head flex flex_between align_center">
                <ul class="home_course_head_list flex align_center">
                    <li :class="{'active':active == 0}" @click="active = 0">豫见非遗</li>
                    <li :class="{active:active == 1}" @click="active = 1">工匠印象</li>
                </ul>
                <div class="home_course_head_more" @click="pageJumps('Intangible')">更多>></div>
            </div>
            <div class="home_course_main">
                <ul class="home_course_main_list" v-if="active == 0">
                    <li
                        @click="pageJumps('IntanDetails',{id:item.id})"
                        v-for="(item,index) in culturalList"
                        :key="index"
                    >
                        <div class="home_course_main_list_img">
                            <img :src="item.image" alt />
                        </div>
                        <div class="home_course_main_list_txt">
                            <div class="home_course_main_list_name">{{item.name}}</div>
                        </div>
                    </li>
                </ul>
                <ul class="home_course_main_list" v-if="active == 1">
                    <li
                        @click="pageJumps('artisan',{id:item.id})"
                        v-for="(item,index) in ArtisanList"
                        :key="index"
                    >
                        <div class="home_course_main_list_img">
                            <img :src="item.image" alt />
                        </div>
                        <div class="home_course_main_list_txt">
                            <div class="home_course_main_list_name">{{item.name}}</div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <!-- E 非遗 工匠 -->
        <Warm />
        <register ref="register"></register>
        <forgetPassword ref="forgetPassword"></forgetPassword>
        <apply ref="apply"></apply>
    </div>
</template>

<script>
import register from "@/components/register";
import forgetPassword from "@/components/forgetPassword";
import apply from "@/components/apply";
export default {
    components: {
        register,
        forgetPassword,
        apply,
    },
    data() {
        return {
            bannerList: [], //轮播图
            consultList: [], //咨询
            rememberPwd: false, // 记住密码
            account: "", //手机号
            password: "", //密码
            trainList: [], //培训课程
            myCoursesList: [], // 我的课程列表
            culturalList: [], // 非遗列表
            ArtisanList: [], // 工匠列表
            recommendDzcourseList: [], // 首页的定制
            active: 0, // 0 非遗 1工匠
            adInfo: {}, // 广告
            isApprove:false,//是否实名认证过
        };
    },
    computed: {
        isLogin() {
            return this.$store.getters.getIsLogin;
        },
    },
    methods: {
        // 立即报名
        applySubmit(item) {
          if (this.isApprove) {
            this.$store.commit("changeNoodeRenzheng", true);
          } else {
             if (this.judgeIsLogin()) {
                this.$refs.apply.show(item);
            }
          }
          
           
        },
        //去咨询详情
        goNoticeMessage(id, type) {
            this.$router.push({
                name: "NoticeMessage",
                query: { id: id, type: type },
            });
        },
        getBannerList() {
            this.$axios.post(this.$api.indexBanner).then((res) => {
                if (res.code === 1) {
                    this.bannerList = res.data;
                }
            });
        },
        // 获取首页新闻
        getConsultList() {
            this.$axios.post("/api/index/newsCategory").then((res) => {
                if (res.code === 1) {
                    this.consultList = res.data;
                    for (let i = 0; i < this.consultList.length; i++) {
                        let obj = this.consultList[i];
                        this.getNewList(obj.cid, i, obj);
                    }
                    this.$forceUpdate();
                }
            });
        },
        //   获取新闻列表
        async getNewList(cid, i, obj) {
            await this.$axios
                .post(this.$api.newsList, {
                    cid: cid,
                    per_page: 8,
                    page: 1,
                })
                .then((res) => {
                    if (res.code === 1) {
                        if (res.data.list.data.length) {
                            obj.list = res.data.list.data;
                            this.consultList[i] = obj;
                            this.$forceUpdate();
                        }
                    }
                });
        },
        //获取培训课程列表
        getTrainList() {
            let api = "";
            if (!this.judgeIsLogin(false)) {
                api = this.$api.recommendCourseList;
            } else {
                api = this.$api.drecommendCourseList;
            }
            this.$axios.post(api).then((res) => {
                if (res.code === 1) {
                    this.trainList = res.data.data;
                }
            });
        },
        // 获取首页非遗
        getFeiYi() {
            this.$axios
                .post(this.$api.recommendCulturalList, {
                    per_page: 10,
                    page: 1,
                })
                .then((res) => {
                    if (res.code === 1) {
                        this.culturalList = res.data.data;
                    }
                });
        },
        // 获取首页工匠
        getArtisanList() {
            this.$axios
                .post(this.$api.recommendArtisanList, {
                    per_page: 10,
                    page: 1,
                })
                .then((res) => {
                    if (res.code === 1) {
                        this.ArtisanList = res.data.data;
                    }
                });
        },
        // 获取我的课程列表
        getMyCoursesList() {
            this.$axios
                .post(this.$api.myCourseList, {
                    per_page: 10,
                    page: 1,
                })
                .then((res) => {
                    if (res.code === 1) {
                        this.myCoursesList = res.data.data;
                    } else if (res.code === 402) {
                      this.isApprove = true
                      this.myCoursesList = [];
                    }
                     else {
                      
                        this.myCoursesList = [];
                    }
                });
        },
        // 获取首页的定制
        getRecommendDzcourseList() {
            this.$axios
                .post(this.$api.recommendDzcourseList, {
                    per_page: 10,
                    page: 1,
                })
                .then((res) => {
                    if (res.code === 1) {
                        this.recommendDzcourseList = res.data.data;
                    }
                });
        },
        //退出登录
        dropdownFn(e) {
            if (e === "退出") {
                sessionStorage.removeItem("token");
                localStorage.removeItem("userInfo");
                this.$store.commit("upIsLogin", false);
            } else if (e === "学习") {
                this.$router.push({
                    path: "/my",
                });
            }
        },
        //登录
        login() {
            if (!this.account)
                return this.$message({
                    message: "请输入手机号或身份证号",
                    type: "warning",
                });
            if (!this.password)
                return this.$message({
                    message: "请输入密码",
                    type: "warning",
                });
            this.$axios
                .post(this.$api.accountLogin, {
                    account: this.account,
                    password: this.password,
                })
                .then((res) => {
                    if (res.code == 1) {
                        this.$message({
                            message: res.msg,
                            type: "success",
                        });
                        sessionStorage.setItem("token", res.data.token);
                        this.$store.commit("upIsLogin", true);
                        localStorage.setItem("rememberPwd", this.rememberPwd);
                        this.getMyCoursesList();
                        this.getTrainList();
                        if (!this.rememberPwd) {
                            this.account = "";
                            this.password = "";
                            localStorage.removeItem("account");
                            localStorage.removeItem("password");
                        } else {
                            localStorage.setItem("account", this.account);
                            localStorage.setItem("password", this.password);
                        }
                        this.$axios.get(this.$api.myinfo).then((ress) => {
                            if (ress.code === 1) {
                                this.$store.commit("upUserInfo", ress.data);
                            }
                        });
                    }
                });
        },
        // 去我的课程列表
        goToCourse() {
            this.pageJumps("my");
            sessionStorage.setItem("myindex", 0);
        },
        // 打开新页面
        openWindow(link) {
            if (/^[http | https]/.test(link)) {
                window.open(link);
            } else {
                window.open("https://" + link);
            }
        },
    },
    created() {
        this.getConsultList();
        this.getBannerList();
        this.getTrainList();
        this.getFeiYi();
        this.getArtisanList();
        this.getRecommendDzcourseList();
        if (this.isLogin) {
            this.getMyCoursesList();
        }
        this.account =
            (localStorage.getItem("account") &&
                localStorage.getItem("account")) ||
            "";
        this.password =
            (localStorage.getItem("password") &&
                localStorage.getItem("password")) ||
            "";
        this.rememberPwd =
            (localStorage.getItem("rememberPwd") &&
                JSON.parse(localStorage.getItem("rememberPwd"))) ||
            false;
        this.$axios.post("/api/index/indexAdvert").then((res) => {
            if (res.code == 1) {
                this.adInfo = res.data;
            }
        });
    },
};
</script>
<style scoped lang="scss">
.home {
    width: 1200px;
    margin: 0 auto;
    padding-bottom: 40px;
    background: #ffffff;
    .home_swiper {
        position: relative;
        .home_swiper_logo {
            position: absolute;
            right: 30px;
            bottom: 10px;
            width: 270px;
            height: 270px;
            background: #ffffff;
            border-radius: 3px;
            z-index: 221;
            .home_swiper_logo_wu {
                .home_swiper_logo_wu_head {
                    width: 270px;
                    height: 42px;
                    color: #666666;
                    font-size: 14px;
                    background: #f4f4f4;
                    border-radius: 3px 3px 0px 0px;
                }
                .home_swiper_logo_list {
                    padding: 20px 15px;
                    li {
                        height: 40px;
                        background: #ffffff;
                        border: 1px solid #e8e8e8;
                        border-radius: 4px;
                        padding: 0 20px;
                        &:first-child {
                            margin-bottom: 15px;
                        }
                        input {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
                .home_swiper_logo_wu_btns {
                    padding: 0 15px;
                    margin-bottom: 20px;
                    .home_swiper_logo_wu_btn {
                        width: 240px;
                        height: 40px;
                        color: #ffffff;
                        font-size: 14px;
                        background: #1263ff;
                        border-radius: 4px;
                    }
                }
                .home_swiper_logo_wu_footer {
                    padding: 0 15px;
                    .home_swiper_logo_wu_footer_l {
                        .remember {
                            .remember_img {
                                cursor: pointer;
                                img {
                                    width: 14px;
                                    height: 14px;
                                    margin-right: 10px;
                                }
                                span {
                                    color: #666666;
                                    font-size: 12px;
                                }
                            }
                            .remember_register {
                                cursor: pointer;
                                color: #666666;
                                font-size: 12px;
                            }
                        }
                    }
                    .home_swiper_logo_wu_footer_r {
                        cursor: pointer;
                        color: #666666;
                        font-size: 12px;
                    }
                }
            }
        }
        .home_swiper_logo_have {
            position: relative;
            .home_swiper_logo_have_head {
                width: 270px;
                height: 42px;

                cursor: pointer;
                background: #f4f4f4;
                border-radius: 3px 3px 0px 0px;
                .head {
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                }
                span {
                    color: #1263ff;
                    font-size: 14px;
                    margin: 0 10px;
                }
                .xiala {
                    width: 12px;
                    height: 8px;
                }
            }
            .home_swiper_logo_have_box {
                margin-bottom: 35px;
                margin-top: 30px;
                padding: 0 10px;
                overflow: hidden;
                .home_swiper_logo_have_list {
                    height: 90px;
                    width: 290px;
                    overflow-y: scroll;
                    li {
                        display: flex;
                        align-items: center;
                        .home_swiper_logo_have_img {
                            width: 120px;
                            height: 90px;
                            margin-right: 10px;
                        }
                        .home_swiper_logo_have_txt {
                            .home_swiper_logo_have_txt_name {
                                color: #333333;
                                font-size: 16px;
                            }
                            .home_swiper_logo_have_txt_time {
                                margin: 5px 0;
                                color: #a2a3a3;
                                font-size: 12px;
                            }
                            .home_swiper_logo_have_txt_jindu {
                                width: 120px;
                                height: 7px;
                                background: #f4f4f4;
                                border-radius: 4px;
                                display: flex;
                                align-items: center;
                                .dot {
                                    width: 56px;
                                    height: 3px;
                                    background: #1263ff;
                                    border-radius: 2px;
                                }
                            }
                            .home_swiper_logo_have_txt_stude {
                                margin-top: 10px;
                                span {
                                    color: #333333;
                                    font-size: 12px;
                                    &:last-child {
                                        color: #1263ff;
                                        margin-left: 5px;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .home_swiper_logo_have_btn {
                width: 240px;
                height: 40px;
                color: #ffffff;
                font-size: 14px;
                background: #1263ff;
                border-radius: 6px;
                margin: 0 auto;
                cursor: pointer;
            }
        }
    }
    .home_advertising {
        margin-top: 20px;
        .home_advertising_list {
            li {
                width: 386px;
                .home_advertising_list_head {
                    width: 100%;
                    padding: 10px 0;
                    border-bottom: 2px solid #d8d8d8;
                    .home_advertising_list_name {
                        color: #333333;
                        font-size: 16px;
                        font-weight: bold;
                        position: relative;
                        &::after {
                            position: absolute;
                            content: "";
                            width: 74px;
                            height: 2px;
                            background: #1263ff;
                            bottom: -12px;
                            left: 0;
                        }
                    }
                    .home_advertising_list_more {
                        color: #999999;
                        font-size: 12px;
                        cursor: pointer;
                    }
                }
                .home_advertising_list_bot {
                    .home_advertising_list_li {
                        font-size: 14px;
                        width: 386px;
                        cursor: pointer;
                        color: #333333;
                        text-align: left;
                        line-height: 21px;
                        margin-top: 10px;
                        padding-left: 15px;
                        position: relative;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        &::after {
                            position: absolute;
                            left: 0;
                            top: 50%;
                            content: "";
                            transform: translateY(-50%);
                            width: 4px;
                            height: 4px;
                            background: #999999;
                            border-radius: 50%;
                        }
                        &:hover {
                            color: #1263ff;
                        }
                    }
                }
            }
        }
    }
    .home_link {
        height: 65px;
        cursor: pointer;
        margin-top: 30px;
    }
    .home_course {
        .home_course_head {
            padding: 15px 10px;
            background: #f2f2f2;
            .home_course_head_list {
                li {
                    width: 74px;
                    text-align: center;
                    cursor: pointer;
                    color: #333333;
                    font-size: 16px;
                    line-height: 21px;
                    margin-right: 30px;
                }
                .active {
                    color: #1263ff;
                    font-weight: bold;
                    position: relative;
                    &::after {
                        content: "";
                        bottom: -15px;
                        left: 0;
                        width: 74px;
                        height: 2px;
                        background: #1263ff;
                        position: absolute;
                    }
                }
            }
            .home_course_head_more {
                color: #999999;
                font-size: 12px;
                cursor: pointer;
            }
        }
        .home_course_main {
            background: #ffffff;
            .home_course_main_list {
                display: flex;
                flex-wrap: wrap;
                li {
                    width: 227px;
                    cursor: pointer;
                    background: #ffffff;
                    box-shadow: 0px 0px 10px 0px rgba(211, 211, 211, 0.3);
                    border-radius: 4px;
                    margin-right: 15px;
                    margin-top: 20px;
                    &:nth-child(5n) {
                        margin-right: 0;
                    }
                    .home_course_main_list_img {
                        width: 227px;
                        height: 170px;
                        position: relative;
                        img {
                          object-fit: cover;
                            border-radius: 4px 4px 0px 0px;
                        }
                        span {
                            color: #fefefe;
                            font-size: 12px;
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            width: 100%;
                            line-height: 34px;
                            background: linear-gradient(
                                0deg,
                                rgba(0, 0, 0, 0.6),
                                rgba(0, 0, 0, 0)
                            );
                            text-align: center;
                        }
                    }
                    .home_course_main_list_txt {
                        padding: 15px 10px;
                        .home_course_main_list_name {
                            font-size: 14px;
                            overflow: hidden;
                            width: 200px;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                        .home_course_main_list_title {
                            margin-top: 10px;
                            .home_course_main_list_title_l {
                                span {
                                    font-size: 12px;
                                    &:first-child {
                                        color: #999999;
                                    }
                                    &:last-child {
                                        color: #1263ff;
                                        margin-left: 5px;
                                    }
                                }
                            }
                            .home_course_main_list_title_btn {
                                width: 76px;
                                height: 29px;
                                color: #ffffff;
                                font-size: 12px;
                                background: #1263ff;
                                border-radius: 15px;
                            }
                        }
                    }
                }
            }
        }
    }
}
input::-webkit-input-placeholder {
    color: #999999;
    font-size: 16px;
}
input::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #999999;
    font-size: 16px;
}
input:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #999999;
    font-size: 16px;
}
input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #999999;
    font-size: 16px;
}
/deep/ .el-carousel__arrow {
    display: none !important;
}
</style>
