<template>
    <div class="login-dia">
        <el-dialog title="忘记密码" :visible.sync="centerDialogVisible" width="700px" center>
            <div class="column_align">
                <input
                    class="yh-w520 yh-h64 yh-fz26 yh-ptb0lr20 yh-bbox yh-mt20 yh-border1"
                    type="text"
                    v-model="form.mobile"
                    oninput="value=value.replace(/[^\d]/g,'')"
                    minlength="11"
                    maxlength="11"
                    placeholder="请输入手机号"
                    placeholder-class="yh-fc999"
                />
                <div class="yh-pr">
                    <input
                        class="yh-w520 yh-h64 yh-fz26 yh-ptb0lr20 yh-bbox yh-mt20 yh-border1"
                        type="text"
                        v-model="form.code"
                        oninput="value=value.replace(/[^\d]/g,'')"
                        pattern="/\d{6,6}/"
                        minlength="6"
                        maxlength="6"
                        placeholder="请输入验证码"
                        placeholder-class="yh-fc999"
                    />
                    <span
                        @click="getCode"
                        class="yanzheng yh-mt10 yh-pyc yh-lh44 yh-cp yh-zi10 yh-fc yh-fz14 yh-w140 center_combo"
                    >{{codeMsg}}</span>
                </div>
                <input
                    class="yh-w520 yh-h64 yh-fz26 yh-ptb0lr20 yh-bbox yh-mt20 yh-border1"
                    type="password"
                    v-model="form.password"
                    minlength="8"
                    maxlength="18"
                    placeholder="设置密码：8到18位好汉数字和字母"
                    placeholder-class="yh-fc999"
                />
                <input
                    class="yh-w520 yh-h64 yh-fz26 yh-ptb0lr20 yh-bbox yh-mt20 yh-border1"
                    type="password"
                    v-model="password1"
                    minlength="8"
                    maxlength="18"
                    placeholder="请再次输入密码"
                    placeholder-class="yh-fc999"
                />
                <button
                    class="login-btn yh-fz24 yh-bc yh-w520 yh-h64 yh-fcF yh-mt60 yh-cp yh-br4"
                    @click="submit"
                >确定</button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import $DB from "@/utils/debounce";
export default {
    name: "forgetPassword",
    data() {
        return {
            centerDialogVisible: false, // 弹框
            form: {
                mobile: "",
                password: "",
                code: "",
            },
            password1: "", //确认密码
            times: 60, // 倒计时
            codeMsg: "获取验证码",
            timers: null, // 倒计时定时器
            isCilik: false, // 是否已经点击
        };
    },
    watch: {},
    methods: {
        show() {
            this.centerDialogVisible = true;
        },
        //  获取验证码
        getCode: $DB(function () {
            if (this.isCilik) return;
            if (!this.form.mobile)
                return this.$message({
                    message: "请填写手机号",
                    type: "warning",
                });
            if (!this.$regexPhone.test(this.form.mobile))
                return this.$message({
                    message: "请检查手机号",
                    type: "warning",
                });

            this.isCilik = true;
            this.$axios
                .post(this.$api.sendSms, {
                    event: "forgetpwd",
                    mobile: this.form.mobile,
                })
                .then((res) => {
                    this.isCilik = false;
                    if (res.code == 1) {
                        this.timers = setInterval(() => {
                            if (this.times > 0 && this.times <= 60) {
                                this.times--;
                                if (this.times != 0) {
                                    this.codeMsg = this.times;
                                } else {
                                    this.codeMsg = "重新获取";
                                    clearInterval(this.timers);
                                    this.times = 60;
                                    this.timers = null;
                                }
                            }
                        }, 1000);

                        // this.times = 59;
                        // this.timers = setInterval(() => {
                        //   this.times--;
                        // }, 1000);
                        this.$message({
                            message: res.msg,
                            type: "warning",
                        });
                    }
                });
        }),

        // 确认
        submit: $DB(function () {
            let { mobile, password, code } = this.form;
            if (!mobile)
                return this.$message({
                    message: "请填写手机号",
                    type: "warning",
                });
            if (!this.$regexPhone.test(mobile))
                this.$message({ message: "请检查手机号", type: "warning" });
            if (!code)
                return this.$message({
                    message: "请填写验证码",
                    type: "warning",
                });
            if (!password)
                return this.$message({
                    message: "请设置密码",
                    type: "warning",
                });
            if (password.length < 6)
                return this.$message({
                    message: "密码不得小于6位",
                    type: "warning",
                });
            if (password !== this.password1)
                return this.$message({
                    message: "输入的密码不一致",
                    type: "warning",
                });

            this.$axios
                .post(this.$api.forgetPassword, this.form)
                .then((res) => {
                    if (res.code == 1) {
                        this.$message({
                            message: res.msg,
                            type: "warning",
                        });
                        sessionStorage.setItem("token", res.data.token);
                        this.$axios.get(this.$api.myinfo).then((ress) => {
                            if (ress.code === 1) {
                                this.$store.commit("upUserInfo", ress.data);
                            }
                        });
                        this.$store.commit("upIsLogin", true);
                        this.centerDialogVisible = false;

                        location.reload();
                    }
                });
        }),
    },
};
</script>

<style lang="scss">
.login-dia {
    .el-dialog__header {
        background: #f7faff;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        padding: 10px 20px;
        font-size: 26px;
    }

    .el-dialog__title {
        color: #1263ff;
    }
    .el-dialog__headerbtn .el-dialog__close {
        color: #d9d9d9;
    }
    .el-icon-close:before {
        content: "\e79d";
    }
}

input[type="number"] {
    -moz-appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.yanzheng {
    right: 0;
    border-left: 1px solid #999999;
}
</style>
