<template>
  <div class="login-dia">
    <el-dialog
        :title="noticeInfo.textTitle"
        :visible.sync="centerDialogVisible"
        width="700px"
        center>
      <div class="" v-html="noticeInfo.context">

      </div>
    </el-dialog>
  </div>
</template>

<script>

export default {
  name: "notice",
  props: {
    //  富文本内容
    noticeInfo: {
      type: Object
    },
  },
  data(){
    return {
      centerDialogVisible: false
    }
  },
  methods:{
    show(){
      this.centerDialogVisible = true
    }
  }
}
</script>

<style scoped lang="scss">
.login-dia{
  .el-dialog__header{
    background: #F7FAFF;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 10px 20px ;
    font-size: 26px;
  }

  .el-dialog__title{
    color: #1263FF;
  }
  .el-dialog__headerbtn .el-dialog__close{
    color: #D9D9D9;
  }
  .el-icon-close:before{
    content: '\e79d'
  }
}
</style>