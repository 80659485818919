let apiObj = {
	// 我的登录注册相关 S
	register: "/api/login/register", // 注册
	get_info: "/api/Study/get_info", // 注册
	accountLogin: "/api/login/accountLogin", // 账号登录
	myinfo: "/api/mine/info", // 我的基本信息
	logout: "/api/login/logout", // 退出登录
	realNameAuth: "/api/mine/realNameAuth", // 实名认证
	forgetPassword: "/api/login/forgetPassword",//忘记密码
	changeAvatar: "/api/mine/changeAvatar", //修改头像
	classes: "/api/mine/classes", //我的档案 ---获取我的所有班级
	MyRecord: "/api/mine/record", //我的档案
	recordChapter: "/api/mine/chapter", //我的档案 章节详情
	recordPhoto: "/api/mine/photo", //我的档案 查看抓拍
	recordNote: "/api/mine/note", //我的档案 查看笔记
	recordAddNote: "/api/mine/addNote", //我的档案 上传笔记
	jian_10: "/api/study/jian_10", //
	// 我的登录注册相关 E

	// 共工 S
	sendSms: "/api/common/sendSms", // 发送短信验证码
	upload_one_image: "/api/upload/upload_one_image", // 上传单张图片
	upload_images: "/api/upload/upload_images", // 上传多张图片
	// 共工 E

	// 首页 S
	// indexBanner: "/api/index/indexBanner", // 首页轮播图
	indexBanner: "api/index/pcindexBanner", // PC首页轮播图
	recommendCourseList: "/api/index/recommendCourseList", // 首页推荐课程列表
	drecommendCourseList: "/api/index/drecommendCourseList", // 首页推荐课程列表 登录后
	recommendCulturalList: "/api/index/recommendCulturalList", // 首页非遗列表
	indexNewsList: "/api/index/indexNewsList", // 首页资讯列表
	hotlink: "/api/index/hotlink", // 热词
	recommendDzcourseList: "/api/index/recommendDzcourseList", // 首页定制
	recommendArtisanList: "/api/index/recommendArtisanList", // 首页工匠
	// 首页 E

	// 非遗 资讯类别及详情 S
	culturalCategory: "/api/index/culturalCategory", //非遗类别
	culturalList: "/api/index/culturalList",   //通过类别ID获取非遗列表
	newsCategory: "/api/index/newsCategory", //资讯类别
	newsList: "/api/index/newsList",  //通过类别ID获取资讯列表
	newsDetail: "/api/index/newsDetail",  //咨询详情
	culturalDetail: "/api/index/culturalDetail", //非遗详情
	artisanDetail: "/api/index/artisanDetail", //非遗详情
	// 非遗 资讯类别及详情 E

	//课程显示
	appletsReview: 'api/study/appletsReview',
	locus: 'api/study/locus',//记录

	// 课程学习 S
	courseCategoryList: "/api/index/courseCategoryList", // 课程类别列表
	courseList: "/api/index/courseList", // 通过类别获取课程列表
	dCourseList: "/api/index/dCourseList", // 通过类别获取课程列表 登录以后的
	courseDetail: "/api/index/courseDetail", // 课程详情
	myCourseList: "/api/study/myCourseList", // 我的课程列表
	faceAuth: "/api/study/faceAuth", // 人脸识别
	chapterDetail: "/api/index/chapterDetail", // 章节详情（课程列表）
	taskReport: "/api/study/taskReport", // 上报视频进度
	taskBack: "/api/study/taskBack", // 请求视频进度倒退
	sign_up: "/api/study/sign_up", // 报名
	signUpOption: "/api/study/signUpOption", // 报名选项
	dzcourseCategory: "/api/index/dzcourseCategory", // 定制课程分类
	dzcourseList: "/api/index/dzcourseList", // 定制课程列表
	ddzcourseList: "/api/index/ddzcourseList", // 定制课程列表 登录后的
	dzcourseDetail: "/api/index/dzcourseDetail", // 定制课程详情
	ddzcourseDetail: "/api/index/ddzcourseDetail", // 定制课程详情 登录后的
	search: "/api/index/search", //搜索课程
	// 课程学习 E

	// 协议说明 S
	registrAgreement: "/api/common/registrAgreement", //用户注册协议
	privacyPolicy: "/api/common/privacyPolicy", //隐私政策
	faceCollectAgreement: "/api/common/faceCollectAgreement", //服务协议
	aboutUs: "/api/common/about",  //关于我们
	faceAuthSwitch: "/api/study/faceAuthSwitch",  //关于我们
	signupNotice: "/api/common/signupNotice", //线上报名承诺书
	bannerText: "/api/index/bannerText", //首页轮播图富文本详情
	newshelpDetail: "/api/index/newshelpDetail", //帮助中心的详情
	// 协议说明 E

}

export default apiObj
