<template>
    <div class="login-dia">
        <el-dialog title="注册" :visible.sync="centerDialogVisible" width="686px" center>
            <div class="column_align">
                <input
                    class="yh-w520 yh-h64 yh-fz26 yh-ptb0lr20 yh-bbox yh-mt10 yh-border1"
                    type="text"
                    v-model="form.realname"
                    placeholder="请输入姓名"
                />
                <!-- <input
                    class="yh-w520 yh-h64 yh-fz26 yh-ptb0lr20 yh-bbox yh-mt20 yh-border1"
                    type="text"
                    maxlength="18"
                    v-model="form.idcardnumber"
                    placeholder="请输入身份证号"
                /> -->
                <input
                    class="yh-w520 yh-h64 yh-fz26 yh-ptb0lr20 yh-bbox yh-mt20 yh-border1"
                    type="text"
                    v-model="form.mobile"
                    oninput="value=value.replace(/[^\d]/g,'')"
                    minlength="11"
                    maxlength="11"
                    placeholder="请输入手机号"
                    placeholder-class="yh-fc999"
                />
                <div class="yh-pr">
                    <input
                        class="yh-w520 yh-h64 yh-fz26 yh-ptb0lr20 yh-bbox yh-mt20 yh-border1"
                        type="text"
                        v-model="form.code"
                        oninput="value=value.replace(/[^\d]/g,'')"
                        minlength="6"
                        maxlength="6"
                        placeholder="请输入验证码"
                        placeholder-class="yh-fc999"
                    />
                    <span
                        v-if="!time"
                        @click="getCode"
                        class="yanzheng yh-mt10 yh-pyc yh-lh44 yh-cp yh-zi10 yh-fc yh-fz14 yh-w140 center_combo"
                    >获取验证码</span>
                    <span
                        v-else
                        class="yanzheng yh-mt10 yh-pyc yh-lh44 yh-cp yh-zi10 yh-fc yh-fz14 yh-w140 center_combo"
                    >{{time}}</span>
                </div>
                <input
                    class="yh-w520 yh-h64 yh-fz26 yh-ptb0lr20 yh-bbox yh-mt20 yh-border1"
                    type="password"
                    v-model="form.password"
                    minlength="8"
                    maxlength="18"
                    placeholder="设置密码：8到18位包含数字和字母"
                    placeholder-class="yh-fc999"
                />
                <input
                    class="yh-w520 yh-h64 yh-fz26 yh-ptb0lr20 yh-bbox yh-mt20 yh-border1"
                    type="password"
                    v-model="password1"
                    minlength="8"
                    maxlength="18"
                    placeholder="请再次输入密码"
                    placeholder-class="yh-fc999"
                />
                <button
                    @click="submit"
                    class="login-btn yh-fz24 yh-bc yh-w520 yh-h64 yh-fcF yh-mt80 yh-cp yh-br4"
                >确定</button>
                <div class="level_align yh-mt30" @click="isRead = !isRead">
                    <img class="yh-img24 yh-mr20" v-if="isRead" src="../assets/image/yudu.png" alt />
                    <img class="yh-img24 yh-mr20" v-else src="../assets/image/yudu2.png" alt />
                    我已阅读并同意提交承诺书
                    <span
                        class="yh-fc-blue yh-cp"
                        @click.stop="getApi('registrAgreement','用户注册协议')"
                    >《用户注册协议》</span>和
                    <span
                        class="yh-fc-blue yh-cp"
                        @click.stop="getApi('privacyPolicy','隐私政策')"
                    >《隐私政策》</span>
                </div>
            </div>
        </el-dialog>
        <notice ref="notice" :noticeInfo="noticeInfo" @NoticeOk="isRead = true"></notice>
    </div>
</template>

<script>
import notice from "@/components/notice";
import $DB from "@/utils/debounce";
export default {
    name: "register",
    components: {
        notice,
    },
    data() {
        return {
            centerDialogVisible: false, // 弹框
            isRead: false, // 是否阅读
            form: {
                realname: "",
                idcardnumber: "",
                mobile: "",
                password: "",
                code: "",
            },
            password1: "",
            time: 0, // 倒计时
            timer: null, // 倒计时定时器
            isCilik: false, // 是否已经点击
            noticeInfo: {
                textTitle: "",
                context: "",
            },
        };
    },
    watch: {
        time(val) {
            if (val <= 0) {
                this.time = 0;
                clearInterval(this.timer);
            }
        },
    },
    methods: {
        show() {
            this.centerDialogVisible = true;
        },
        // 获取验证码
        getCode: $DB(function () {
            if (this.isCilik) return;
            if (!this.form.mobile)
                return this.$message({
                    message: "请填写手机号",
                    type: "warning",
                });
            if (!this.$regexPhone.test(this.form.mobile))
                return this.$message({
                    message: "请检查手机号",
                    type: "warning",
                });

            this.isCilik = true;
            this.$axios
                .post(this.$api.sendSms, {
                    event: "register",
                    mobile: this.form.mobile,
                })
                .then((res) => {
                    this.isCilik = false;
                    if (res.code == 1) {
                        this.time = 59;
                        this.timer = setInterval(() => {
                            this.time--;
                        }, 1000);
                        this.$message({
                            message: res.msg,
                            type: "warning",
                        });
                    }
                });
        }),
        submit: $DB(function () {
            let { realname, mobile, password, code } = this.form;
            if (!realname)
                return this.$message({
                    message: "请填写姓名",
                    type: "warning",
                });
            // if (!idcardnumber)
            //     return this.$message({
            //         message: "请填写身份证号",
            //         type: "warning",
            //     });
            // if (
            //     !"/^(^[1-9]d{7}((0d)|(1[0-2]))(([0|1|2]d)|3[0-1])d{3}$)|(^[1-9]d{5}[1-9]d{3}((0d)|(1[0-2]))(([0|1|2]d)|3[0-1])((d{4})|d{3}[Xx])$)$/".test(
            //         idcardnumber
            //     )
            // )
            //     return this.$message({
            //         message: "请检查身份证号是否正确",
            //         type: "warning",
            //     });
            if (!mobile)
                return this.$message({
                    message: "请填写手机号",
                    type: "warning",
                });
            if (!this.$regexPhone.test(mobile))
                this.$message({ message: "请检查手机号", type: "warning" });
            if (!code)
                return this.$message({
                    message: "请填写验证码",
                    type: "warning",
                });
            if (!password)
                return this.$message({
                    message: "请设置密码",
                    type: "warning",
                });
            if (password.length < 6)
                return this.$message({
                    message: "密码不得小于6位",
                    type: "warning",
                });
            if (password !== this.password1)
                return this.$message({
                    message: "输入的密码不一致",
                    type: "warning",
                });
            if (!this.isRead)
                return this.$message({
                    message: "请阅读协议",
                    type: "warning",
                });
            this.$axios.post(this.$api.register, this.form).then((res) => {
                if (res.code == 1) {
                    this.$message({
                        message: res.msg,
                        type: "warning",
                    });
                    sessionStorage.setItem("token", res.data.token);
                    this.$axios.get(this.$api.myinfo).then((ress) => {
                        if (ress.code === 1) {
                            this.$store.commit("upUserInfo", ress.data);
                        }
                    });
                    this.$store.commit("upIsLogin", true);
                    this.centerDialogVisible = false;

                    location.reload();
                }
            });
        }),
        // 用户协议
        getApi(api, title) {
            this.$axios.get(this.$api[api]).then((res) => {
                if (res.code == 1) {
                    this.noticeInfo.context = res.data.value;
                    this.noticeInfo.textTitle = title;
                    this.$refs.notice.show();
                }
            });
        },
    },
};
</script>

<style  lang="scss">
.login-dia {
    .el-dialog__header {
        background: #f7faff;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        padding: 10px 20px;
        font-size: 26px;
    }

    .el-dialog__title {
        color: #1263ff;
    }
    .el-dialog__headerbtn .el-dialog__close {
        color: #d9d9d9;
    }
    .el-icon-close:before {
        content: "\e79d";
    }
}
input[type="number"] {
    -moz-appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.yanzheng {
    right: 0;
    border-left: 1px solid #999999;
}
</style>
